@font-face {
  font-family: 'aller_bold';
  src: url('./aller_bold.eot');
  src: url('./aller_bold.eot') format('embedded-opentype'),
    url('./aller_bold.woff2') format('woff2'), url('./aller_bold.woff') format('woff'),
    url('./aller_bold.ttf') format('truetype'), url('./aller_bold.svg#aller_bold') format('svg');
}

@font-face {
  font-family: 'GothamRoundedMedium';
  src: url('./GothamRoundedMedium.eot');
  src: url('./GothamRoundedMedium.eot') format('embedded-opentype'),
    url('./GothamRoundedMedium.woff2') format('woff2'),
    url('./GothamRoundedMedium.woff') format('woff'),
    url('./GothamRoundedMedium.ttf') format('truetype'),
    url('./GothamRoundedMedium.svg#GothamRoundedMedium') format('svg');
}
